<template>
  <div class="top-bar flex justify-end">
    <div class="flex items-center text-white">
      <!-- Volver -->
      <v-button @click="$router.go(-1)" color="grey" size="small" icon="arrow-left" class="mr-8">
        {{ $t('back') }}
      </v-button>

      <!-- Editar Proyecto -->
      <v-button-icon-round v-if="canDisplay('edit')" @click="push({ name: 'project.edit' })" icon="pencil-alt"
        tooltip="edit_project" tooltip-position="bottom" class="mr-1" />

      <!-- Validaciones -->
      <v-button-icon-round v-if="canDisplay('validations')" @click="push({ name: 'project.admin.validations' })"
        icon="project-diagram" tooltip="validations" tooltip-position="bottom" class="mr-1" />

      <!-- Departamentos -->
      <v-button-icon-round v-if="canDisplay('departments')" @click="push({ name: 'project.departments' })"
        icon="code-branch" tooltip="go_to_departments" tooltip-position="bottom" class="mr-1" />
      <!-- Localizaciones -->
      <v-button-icon-round v-if="canDisplay('locations')" @click="push({ name: 'project.admin.locations' })"
        icon="map-marker-alt" tooltip="locations" tooltip-position="bottom" class="mr-1" />
      <!-- Importar/Exportar -->
      <v-button-icon-round v-if="canDisplay('import_export_activities')"
        @click="push({ name: 'project.import-export-activities' })" icon="file-excel"
        :tooltip="'import_export_activities'" tooltip-position="left" class="mr-1" />
      <!-- Planning -->
      <!--<v-button-icon-round-->
      <!--@click="$router.push({name: 'project.planning'})"-->
      <!--icon="chart-line"-->
      <!--tooltip="planning"-->
      <!--tooltip-position="bottom"-->
      <!--class="mr-1"-->
      <!--/>-->
      <!-- Configurar Status Kanban -->
      <v-button-icon-round v-if="canDisplay('manage-statuses')" @click="push({ name: 'project.manage-statuses' })"
        icon="traffic-light" tooltip="manage_statuses" tooltip-position="bottom" class="mr-1" />
      <!-- Mediciones -->
      <v-button-icon-round v-if="canDisplay('assignments')" @click="push({ name: 'project.admin.assignments' })"
        icon="tasks" tooltip="measurements" tooltip-position="bottom" class="mr-1" />
      <!-- Extrafields -->
      <v-button-icon-round v-if="canDisplay('extrafields')" @click="push({ name: 'project.admin.extrafields' })"
        icon="object-ungroup" tooltip="extrafields" tooltip-position="bottom" class="mr-1" />
      <!-- Drawings -->
      <v-button-icon-round v-if="canDisplay('issues')" @click="push({ name: 'project.admin.issues' })"
        icon="exclamation-triangle" tooltip="issues" tooltip-position="bottom" class="mr-1" />
      <!-- Drawings -->
      <v-button-icon-round v-if="canDisplay('drawings')" @click="push({ name: 'project.admin.drawings' })"
        icon="file-pdf" tooltip="documents_drawings" tooltip-position="bottom" class="mr-1" />
      <!-- Drawings -->
      <v-button-icon-round v-if="canDisplay('pictograms')" @click="push({ name: 'project.admin.pictograms' })"
        icon="solar-panel" tooltip="pictograms" tooltip-position="bottom" class="mr-1" />
      <!-- Partners -->
      <v-button-icon-round v-if="canDisplay('partners')" @click="push({ name: 'project.admin.partners' })"
        icon="handshake" tooltip="partners_providers" tooltip-position="bottom" class="mr-1" />
      <!-- Carpetas de documentos -->
      <v-button-icon-round v-if="canDisplay('documents_folders')"
        @click="push({ name: 'project.admin.document-folders' })" icon="folder-plus" tooltip="documents_folders"
        tooltip-position="bottom" class="mr-1" />

      <!-- Almacén -->
      <v-button-icon-round v-if="canDisplay('storehouse')" @click="push({ name: 'project.admin.storehouse' })"
        icon="store-alt" tooltip="storehouse" tooltip-position="bottom" class="mr-1" />
      <!-- Presupuesto de Costes -->
      <v-button-icon-round v-if="canDisplay('cost_budget')" @click="push({ name: 'project.admin.cost-management' })"
        icon="file-invoice-dollar" tooltip="cost_budget" tooltip-position="left" class="mr-1" />

      <!-- Especificaciones técnicas -->
      <v-button-icon-round v-if="canDisplay('technical-specification')"
        @click="push({ name: 'project.admin.technical-specification' })" icon="cogs" tooltip="technical_specification"
        tooltip-position="left" class="mr-1" />

      <!-- Especificaciones técnicas -->
      <v-button-icon-round v-if="canDisplay('work-orders')" @click="push({ name: 'project.admin.work-orders' })"
        icon="person-digging" tooltip="purchase_order" tooltip-position="left" class="mr-1" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: 'project-admin-quick-buttons',

  computed: {
    ...mapGetters({
      navData: "app/navData",
    })
  },

  components: {},

  props: { hiddenOption: { type: String, required: true } },

  data: () => ({}),

  watch: {},

  mounted() {
  },

  methods: {
    canDisplay(option) {
      return option !== this.hiddenOption;
    },

    push(args) {
      this.$store.dispatch('app/updateNavData', {
        project_id: this.navData.project_id,
        project_name: this.navData.project_name
      });

      args.project_id = this.navData.project_id;
      args.project_name = this.navData.project_name;
      this.$router.push(args);
    },

  },

};
</script>
