<template>
  <section>
    <div class="mt-4">
      <!--butons add providers-->
      <section class="top-bar flex justify-end">
        <button class="btn btn-blue rounded"
          @click="isAddProvider = !isAddProvider">
          <b-icon icon="plus" pack="fas"/>
          {{ $t('add') }} {{ $t('providers') }}
        </button>
      </section>
      <!-- tabla add company provider -->
      <div class="panel" v-if="isAddProvider">
        <b-table
          :data="companyProviders"
          :per-page="10"
          :bordered="true"
          :pagination-simple="true"
          checkable
          :checked-rows.sync="checkedProviders"
          :default-sort-direction="defaultSortDirection"
          icon-pack="fas"
          v-if="loaded"
        >
          <template>
            <b-table-column field="name" sortable>
              <template v-slot:header="">{{ $t('name') }}</template>
              <template v-slot="props">{{ props.row.name }}</template>
            </b-table-column>

            <b-table-column field="cif" sortable>
              <template v-slot:header="">CIF</template>
              <template v-slot="props">{{ props.row.CIF }}</template>
            </b-table-column>

            <b-table-column field="code" sortable>
              <template v-slot:header="">{{ $t('code') }}</template>
              <template v-slot="props">{{ props.row.code }}</template>
            </b-table-column>
          </template>
        </b-table>

        <!-- cancel and add buttons-->
         <div class="panel">
          <div class="flex items-center justify-end"> 
            <button class="btn btn-blue rounded m-2"
              @click="addProviders">
              {{ $t('add') }}
            </button>
            <button class="btn btn-blue rounded m-2"
              @click="isAddProvider = false">
              {{ $t('cancel') }}
            </button>
          </div>
        </div>

      </div>
      <!-- tabla de project-providers--> 
      <div class="panel">
        <b-table
          :data="projectProviders"
          :per-page="10"
          :bordered="true"
          :pagination-simple="true"
          :default-sort-direction="defaultSortDirection"
          icon-pack="fas"
          paginated
          pagination
          v-if="loaded"
        >
          <template>
            <b-table-column field="name" sortable>
              <template v-slot:header="">{{ $t('name') }}</template>
              <template v-slot="props">{{ props.row.name }}</template>
            </b-table-column>

            <b-table-column field="cif" sortable>
              <template v-slot:header="">{{ $t('cif') }}</template>
              <template v-slot="props">{{ props.row.CIF }}</template>
            </b-table-column>

            <b-table-column field="code" sortable>
              <template v-slot:header="">{{ $t('code') }}</template>
              <template v-slot="props">{{ props.row.code }}</template>
            </b-table-column>
            <!-- botones de acciones -->
            <b-table-column field="actions" width=100 sortable>
              <template v-slot:header="">{{ $t('actions') }}</template>
              <template v-slot="props">
                <div class="w-full h-full flex items-center justify-around">
                  <b-tooltip :label="$t('remove')" position="is-left" type="is-danger">                   
                    <div class="cursor-pointer text-red">
                      <i class="fas fa-trash-alt" @click="removeProjectProvider(props.row.id)"
                      ></i>
                    </div>
                  </b-tooltip>
                </div>
              </template>
            </b-table-column>
          </template>

          <template slot="bottom-left">
            <b-icon pack="fas" class="flex text-blue" icon="folder"></b-icon>
            <b>{{ $t("total") }}</b>: {{ this.projectProviders.length }}
          </template>

          <template #empty>
            <div class="has-text-centered">{{ $t('no_results') }}</div>
          </template>

        </b-table>       
      </div>
    </div>
  </section>
</template>

<script>


import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
  components: {
  },

  data: () => ({
    projectProviders: [],
    companyProviders: [],
    checkedProviders: [],
    isAddProvider: false,
    defaultSortDirection: 'asc',
    loaded: true,
    project_id: null,
    company_id: null,
  }),

  computed: {
    ...mapGetters({
      navData: 'app/navData',
      company: 'app/company',
    }),
  },

  created() {
    this.project_id = parseInt(this.navData.project_id)
    this.company_id = parseInt(this.company.id)
  },

  mounted() {
    this.getProjectProviders()
    this.getCompanyProviders()
  },

  methods: {
    async addProviders() {
      const companyProviderIds = this.checkedProviders.map(provider => provider.id)
      const { data } = await axios.post(`/api/v2/project/${this.project_id}/provider`, {
        provider_ids: companyProviderIds,
      })

      if (data && data.success) {
        this.getProjectProviders()
        this.isAddProvider = false
        this.checkedProviders = []
        this.$notify.success('Providers added successfully')
      } else {
        this.$notify.error('Error adding providers')
      }

      this.checkedProviders = []
      this.isAddProvider = false
    },

    async removeProjectProvider(providerId) {
      const { data } = await axios.delete(`/api/v2/project-provider/${providerId}`)

      if (data && data.success) {
        this.getProjectProviders()
        this.$notify.success('Provider removed successfully')
      } else {
        this.$notify.error('Error removing provider')
      }
    },

    async getProjectProviders() {
      try {
        const { data } = await axios.get(`/api/v2/project/${this.project_id}/providers`)
        if (data && data.success) {
          this.projectProviders = data.providers
        } else {
          this.$notify.error('Error fetching project providers')
        }
      } catch (error) {
        this.$notify.error('Error fetching project providers')
      }
    },

    async getCompanyProviders() {
      try {
        const { data } = await axios.get(`/api/v2/providers`, {
          params: {
            company_id: this.company_id,
          },
        })

        if (data && data.success) {
          this.companyProviders = data.providers
        } else {
          this.$notify.error('Error fetching company providers')
        }
      } catch (error) {
        this.$notify.error('Error fetching company providers')
      }
    },

  },
}
</script>