<template>
    <div v-if="previewData.length">
        <div class="table-container">
            <b-table :data="previewData" :loading="isLoading" class="border-blue-lighter" bordered narrowed striped>
                <b-table-column v-for="(header, index) in Object.keys(previewData[0])" :key="index" :field="header"
                    :label="$t(header)">
                    <template #default="props">
                        <template v-if="header === 'status_error'">
                            <span v-if="props.row.status_error && props.row.status_error.length" class="text-center">
                                <b-tooltip :label="generateTooltipLabel(props.row.status_error)" position="is-right"
                                    animated size="is-large" multilined type="is-danger">
                                    <b-icon pack="fa" size="is-small" icon="exclamation-triangle" type="is-danger"
                                        class="ml-4"></b-icon>
                                </b-tooltip>
                            </span>
                            <span v-else>
                                <b-icon pack="fa" size="is-small" icon="check-circle" type="is-success"
                                    class="ml-4"></b-icon>
                            </span>
                        </template>
                        <template v-else-if="header === 'actions' && props.row.status_error.length">
                            <span @click="openModal(props.row, props.index)" style="cursor: pointer;">
                                <b-icon pack="fa" size="is-small" icon="edit" class="ml-2"></b-icon>
                            </span>
                        </template>
                        <template v-else>
                            {{ props.row[header] }}
                        </template>
                    </template>
                </b-table-column>
                <import-excel-modal ref="dataModalExcel" @saved="handleSavedChanges"></import-excel-modal>
            </b-table>
        </div>
    </div>
</template>

<script>
import * as XLSX from 'xlsx';
import axios from 'axios';
import ImportExcelModal from "./import-excel-modal.vue";

export default {
    components: {
        ImportExcelModal,
    },
    props: {
        file: {
            type: File,
            default: null,
        },
        projectId: {
            type: Number,
            required: true,
        },
        overwrite: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            previewData: [],
            isLoading: false,
            errors: [],
            chunkSize: 50,
            selectedRowIndex: null
        };
    },

    methods: {

        async sendChunk(chunk) {
            try {
                const { data } = await axios.post('/api/v2/import/activities/optimized', {
                    chunk,
                    project_id: this.projectId,
                    with_update: this.overwrite,
                });

                if (data.errors) {
                    // Actualizar errores en las filas correspondientes en previewData
                    this.updateErrors(data.errors);
                    throw new Error();
                }
            } catch (error) {
                console.error("Error al enviar por chunk", error);
                throw error;
            }
        },

        generateTooltipLabel(errors) {
            return errors.map(error => this.$t(error.error, { data: error.data })).join(', ');
        },

        updateErrors(errors) {
            errors.forEach(errorObj => {
                // Obtener los datos y errores específicos
                const data = errorObj.data;
                const errorMessages = errorObj.errors ? errorObj.errors : [];
                // Buscar el índice en previewData basado en el import_id único
                const index = this.previewData.findIndex(item => item.planning_code === data.planning_code);
                if (index !== -1) {
                    // Actualizar el campo 'status_error' en la fila correspondiente de previewData con los errores
                    this.previewData[index].status_error = errorMessages;
                } else {
                    console.warn(`No se encontró el índice para el import_id '${data.import_id}' en previewData.`);
                }
            });
        },
        async processFile() {
            if (!this.file) {
                return { success: false, message: 'No file selected' };
            }
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                this.isLoading = true;

                reader.onload = async (event) => {
                    const data = event.target.result;
                    try {
                        const workbook = XLSX.read(data, { type: 'binary' });
                        const sheetName = workbook.SheetNames[0];
                        const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
                        const headers = worksheet[0];
                        const rows = worksheet.slice(1);

                        if (!headers.includes('Status')) {
                            headers.unshift('Status');
                            rows.forEach(row => {
                                row.unshift('');
                            });
                        }
                        if (!headers.includes('Actions')) {
                            headers.splice(1, 0, 'Actions');
                            rows.forEach(row => {
                                row.splice(1, 0, '');
                            });
                        }

                        const formattedData = rows.map((row) => {
                            const rowData = {};
                            headers.forEach((header, index) => {
                                rowData[header] = row[index];
                            });
                            return rowData;
                        });

                        // Filtrar las filas vacías
                        const filteredData = formattedData.filter(row => {
                            return Object.values(row).some(value => value !== '' && value !== null && value !== undefined);
                        });

                        const mappedData = filteredData.map((row) => ({
                            status_error: '',
                            actions: '',
                            group_code: row['ID grupo'] == undefined || row['ID grupo'] == null ? '' : `${row['ID grupo']}`,
                            planning_code: row['ID'] == undefined || row['ID'] == null ? '' : `${row['ID']}`,
                            new_planning_code: row['ID a actualizar'] == undefined || row['ID a actualizar'] == null ? '' : `${row['ID a actualizar']}`,
                            parent_activity_planning_code: row['ID actividad madre'] == undefined || row['ID actividad madre'] == null ? '' : `${row['ID actividad madre']}`,
                            name: row['Nombre'] || '',
                            in_planning: row['Planificación'] || '',
                            critical_path: row['Camino crítico'] || '',
                            scheduled_start_date: row['Fecha inicio programada'] || '',
                            scheduled_end_date: row['Fecha fin programada'] || '',
                            current_start_date: row['Fecha inicio actual'] || '',
                            current_end_date: row['Fecha fin actual'] || '',
                            progress: row['Progreso'] || '',
                            weight: row['Ponderación'] || '',
                            email_manager: row['Usuario responsable'] || '',
                            location_reference_code: row['Referencia de localización'] || '',
                            participants: row['Participantes'] || '',
                            collaborators: row['Colaboradores'] || '',
                            tags: row['Etiquetas'] || '',
                            status: row['Estado'] || '',
                            show_on_devices: row['Mostrar en APP'] || '',
                            description: row['Descripción'] || '',
                            document_codes: row['Documentos'] || '',
                            flow_codes: row['Flujos'] || '',
                            id_column: row['ID columna proj'] || '',
                            predecessors: row['Predecesoras'] || '',
                            successors: row['Sucesoras'] || '',
                        }));

                        this.previewData = mappedData;

                        const sendChunksResponse = await this.sendDataChunks(this.previewData);

                        if (sendChunksResponse.success) {
                            resolve({ success: true, message: 'File processed successfully', data: this.previewData });
                        } else {
                            reject({ success: false, message: sendChunksResponse.message });
                        }
                    } catch (error) {
                        console.error('Error reading XLSX data:', error);
                        reject({ success: false, message: 'Error reading XLSX data' });
                    } finally {
                        this.isLoading = false;
                    }
                };

                reader.onerror = (error) => {
                    this.isLoading = false;
                    console.error('Error reading file:', error);
                    reject({ success: false, message: 'Error reading file' });
                };

                reader.readAsBinaryString(this.file);
            });
        },

        async sendDataChunks(dataToSend) {
            try {
                for (let i = 0; i < dataToSend.length; i += this.chunkSize) {
                    const chunk = dataToSend.slice(i, i + this.chunkSize);
                    await this.sendChunk(chunk);
                }
                return { success: true };
            } catch (error) {
                return { success: false, message: 'Error sending data chunks', error: error.message };
            }
        },
        openModal(row, index) {
            this.selectedRowIndex = index;
            this.$refs.dataModalExcel.show(row);
        },
        // Método para manejar los cambios guardados recibidos del modal
        handleSavedChanges(updatedRow) {
            if (this.selectedRowIndex !== null) {
                this.$set(this.previewData, this.selectedRowIndex, updatedRow);
                this.selectedRowIndex = null;
            }
        },

    },
};
</script>

<style scoped>
.table-container {
    overflow-x: auto;
}
</style>
