<template>
    <b-modal :active.sync="isModalActive" :width="1028" scroll="keep" :can-cancel="canCancel" @close="closeModal">
        <div class="border border-blue-light rounded shadow bg-white p-4">
            <form @submit.prevent="saveCostBudget" class="w-full">
                <header class="font-thin uppercase text-blue-light modal-card-head_ mb-4">{{ $t('add_generic') }} / {{
                    $t('edit') }}
                    {{ $t('breakdown') }}
                </header>
                <div class="modal-card-body relative">
                    <div class="w-full flex flex-row mb-3">
                        <div class="w-1/2 pr-4">
                            <b-field :label="$t('select_assignment')" :message="resourceError"
                                :type="resourceError ? 'is-danger' : ''">
                                <multiselect v-model="selectedAssignment" :options="assignments"
                                    :placeholder="$t('select') + ' escribe lo que estas buscando'" :multiple="false"
                                    label="name" track-by="id" :show-labels="false" :searchable="true"
                                    :loading="isLoadingAssignments" noOptions="La lista esta vacía"
                                    @search-change="handleSearchChangeAssignments" :maxHeight="200"
                                    :class="{ 'is-danger': resourceError }" @input="handleAssignmentChange" />
                            </b-field>
                        </div>
                    </div>
                    <div class="w-full flex flex-row mb-3">
                        <div class="w-1/2 mr-4">
                            <b-field :label="$t('reference_code')">
                                <b-autocomplete v-model="form.reference_code" :placeholder="$t('reference_code')"
                                    :keep-first="keepFirst" :open-on-focus="openOnFocus" :data="filteredDataObj"
                                    field="code" @select="onSelect" :clearable="clearable" />
                            </b-field>
                        </div>
                        <b-field :label="$t('select_provider')" class="w-1/2 mr-4">
                            <b-select v-model="form.provider_id" :placeholder="$t('select_provider')" expanded>
                                <option v-for="(provider, index) in providers" :value="provider.provider_id" :key="index">
                                    {{ provider.name }}
                                </option>
                            </b-select>
                        </b-field>
                    </div>
                    <div class="w-full flex flex-row mb-3">
                        <div class="w-1/2 mr-4">
                            <b-field :label="$t('breakdown_name')">
                                <b-autocomplete v-model="form.name" :placeholder="$t('breakdown_name')"
                                    :keep-first="keepFirst" :open-on-focus="openOnFocus" :data="filteredNameData"
                                    field="name" @select="onSelect" :clearable="clearable" />
                            </b-field>
                        </div>
                        <div class="w-1/2 mr-4">
                            <b-field :label="$t('unit_price')">
                                <v-input v-model="form.unit_price" title="unit_price" name="unit_price"
                                    placeholder="unit_price" type="number" step="0.01" />
                            </b-field>
                        </div>
                    </div>
                    <div class="w-full flex flex-row mb-3">
                        <div class="w-1/2 mr-4">
                            <b-field :label="$t('quantity')">
                                <v-input v-model="form.quantity" title="quantity" name="quantity" type="number"
                                    step="0.01" />
                            </b-field>
                        </div>
                        <div class=" w-1/2 mr-4">
                            <b-field :label="$t('coefficient')">
                                <v-input v-model="form.coefficient" title="coefficient" name="coefficient"
                                    placeholder="coefficient" type="number" step="0.01" />
                                <!-- <b-input v-model="form.coefficient" title="coefficient" name="coefficient"
                                    placeholder="coefficient" type="number" step="0.01" /> -->
                            </b-field>
                        </div>
                    </div>

                    <b-loading :is-full-page="false" v-model="processingData" :can-cancel="false"></b-loading>
                </div>
                <footer class="modal-card-foot_">
                    <div class="w-full inline-flex justify-center">
                        <button type="button" class="btn btn-success mr-3" @click="addCost">
                            <b-icon pack="fas" icon="arrow-down-short-wide" class="mr-3">
                            </b-icon>
                            {{ $t('add') + ' ' +
                                $t('breakdown') }}
                        </button>
                    </div>
                    <div class="w-full flex flex-col mb-3 mt-4" v-if="canSave">
                        <table class="custom-table">
                            <thead>
                                <tr>
                                    <th>{{ $t('reference_code') }}</th>
                                    <th>{{ $t('provider') }}</th>
                                    <th>{{ $t('assignment') }}</th>
                                    <th>{{ $t('name') }}</th>
                                    <th>{{ $t('unit_price') }}</th>
                                    <th>{{ $t('quantity') }}</th>
                                    <th>{{ $t('coefficient') }}</th>
                                    <th>{{ $t('actions') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(cost, index) in costs" :key="index">
                                    <td>{{ cost.reference_code }}</td>
                                    <td>{{ getProviderName(cost.provider_id) }}</td>
                                    <td>{{ getAssignmentName(cost.assignment_id) }}</td>
                                    <td>{{ cost.name }}</td>
                                    <td>{{ cost.unit_price }}</td>
                                    <td>{{ cost.quantity }}</td>
                                    <td>{{ cost.coefficient }}</td>
                                    <td>
                                        <b-tooltip :label="$t('delete_breakdown')" position="is-left" type="is-danger">
                                            <div @click="removeCost(index)" class="cursor-pointer flex text-red">
                                                <b-icon pack="fas" icon="trash-alt" />
                                            </div>
                                        </b-tooltip>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="w-full border-t border-gray-300 my-4"></div>

                    <div class="w-full inline-flex justify-end">
                        <button class="btn btn-blue" nativeType="submit" :disabled="!canSave" v-if="!processingData">{{
                            $t('save') }}</button>
                        <v-button-processing v-if="processingData" />
                    </div>
                </footer>
            </form>
        </div>
    </b-modal>
</template>
<script>
import axios from 'axios';
import Multiselect from 'vue-multiselect'
export default {
    components: {
        Multiselect
    },
    props: {
        projectId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            isModalActive: false,
            processingData: false,
            canCancel: ['escape', 'x', 'outside'],
            selectedProvider: null,
            providers: [],
            form: {
                reference_code: '',
                presto_code: '',
                provider_id: null,
                assignment_id: null,
                name: '',
                unit_price: null,
                quantity: null,
                project_id: this.projectId,
                coefficient: null,
            },
            selectedAssignment: null,
            assignments: [],
            originalAssignments: [],
            isLoadingAssignments: false,
            resourceError: null,
            costs: [],
            costBudgets: [],
            keepFirst: false,
            openOnFocus: false,
            selected: null,
            clearable: false,
        }
    },
    computed: {
        canSave() {
            return this.costs.length > 0;
        },
        filteredDataObj() {
            return this.costBudgets.filter((option) => {
                return (
                    option.code
                        .toString()
                        .toLowerCase()
                        .indexOf(this.form.reference_code.toLowerCase()) >= 0
                )
            })
        },
        filteredNameData() {
            return this.costBudgets.filter((option) => {
                return (
                    option.name &&
                    option.name
                        .toString()
                        .toLowerCase()
                        .indexOf(this.form.name ? this.form.name.toLowerCase() : '') >= 0
                );
            });
        }
    },
    methods: {
        show(providers, assignments, costBudgets, budget = null) {
            this.providers = providers;
            this.originalAssignments = assignments;
            this.assignments = assignments;
            this.costBudgets = costBudgets;

            if (budget) {
                this.form.id = budget.id;
                this.form.reference_code = budget.code;
                this.form.presto_code = budget.presto_code;
                this.form.provider_id = budget.provider.id;
                this.form.assignment_id = budget.assignment.assignment_id;
                this.form.name = budget.name;
                this.form.unit_price = budget.unit_price;
                this.form.quantity = budget.quantity;
                this.form.coefficient = budget.coefficient;
            } else {
                this.resetForm();
            }
            this.isModalActive = true;
        },
        closeModal() {
            this.isModalActive = false;
        },
        addCost() {
            // Validate that all required fields have data
            if (
                this.form.reference_code &&
                this.form.provider_id &&
                this.form.assignment_id &&
                this.form.name &&
                this.form.unit_price !== null &&
                this.form.quantity !== null &&
                this.form.coefficient !== null
            ) {
                // Add the current form data to the costs array
                this.costs.push({ ...this.form });
                // Reset the form for the next entry
                this.resetForm();
            } else {
                this.$buefy.toast.open({
                    message: this.$t('fill_all_fields'),
                    type: 'is-danger'
                });
            }
        },
        removeCost(index) {
            // Remove the cost at the specified index
            this.costs.splice(index, 1);
        },
        async saveCostBudget() {
            // Validate that the costs array is not empty
            if (this.costs.length === 0) {
                this.$buefy.toast.open({
                    message: this.$t('add_at_least_one_cost'),
                    type: 'is-danger'
                });
                return;
            }

            this.processingData = true;
            try {
                // Send the array of costs to the server
                await axios.post(`/api/v2/assignment-breakdown`, { costs: this.costs });
                this.$buefy.toast.open({
                    message: this.$t('save_successful'),
                    type: 'is-success'
                });
                this.closeModal();
                this.$emit('reload-list');
            } catch (error) {
                this.$buefy.toast.open({
                    message: this.$t('save_error'),
                    type: 'is-danger'
                });
            } finally {
                this.processingData = false;
                this.costs = [];
                this.selectedAssignment = null;
            }
        },
        resetForm() {
            this.form = {
                id: null,
                reference_code: '',
                presto_code: '',
                provider_id: null,
                resources: '',
                unit_price: null,
                quantity: null,
                coefficient: null,
                project_id: this.projectId,
            };
            this.handleAssignmentChange(this.selectedAssignment);

            // this.selectedAssignment = null;
        },
        handleSearchChangeAssignments(query) {
            if (query.length < 3) {
                this.assignments = this.originalAssignments;
            } else {
                const lowerCaseQuery = query.toLowerCase();
                this.assignments = this.originalAssignments.filter(assignment =>
                    assignment.name.toLowerCase().includes(lowerCaseQuery)
                );
            }
        },
        handleAssignmentChange(selectedAssignment) {
            this.form.assignment_id = selectedAssignment ? selectedAssignment.id : null;
        },
        getProviderName(providerId) {
            const provider = this.providers.find(p => p.id === providerId);
            return provider ? provider.name : '';
        },
        getAssignmentName(assignmentId) {
            const assignment = this.assignments.find(a => a.id === assignmentId);
            return assignment ? assignment.name : '';
        },
        onSelect(option) {
            if (option && option.code) {
                this.form.reference_code = option.code;
                this.form.provider_id = option.provider.id;
                this.form.name = option.name;
                this.form.unit_price = option.unit_price;
                this.form.quantity = option.quantity;
                this.form.coefficient = option.coefficient;
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.custom-table {
    font-size: 0.8rem;

    /* Smaller font size */
    th,
    td {
        padding: 0.25rem;
        /* Smaller padding */
    }

    th {
        background-color: #f5f5f5;
    }

    td {
        background-color: #fff;
    }

    .button.is-small {
        padding: 0.25rem 0.5rem;
    }
}
</style>