<template>
    <div>
        <div class="flex flex-wrap">
            <v-button icon="arrow-left" @click="goToPage('cost_budget')" color="grey" size="small" class="mr-8">{{
                $t('back') }}</v-button>
        </div>
        <h1 class="flex-grow text-blue-light font-bold text-xl uppercase mt-4 mb-2">Nueva Factura</h1>
        <div class="top-bar flex justify-start">
            <b-field :label="$t('select_provider')" class="w-full lg:w-1/4 mr-4">
                <b-select v-model="selectedProvider" :placeholder="$t('select_provider')" icon="store" icon-pack="fas"
                    expanded>
                    <option v-for="(provider, index) in providers" :value="index" :key="index">
                        {{ provider.name }}
                    </option>
                </b-select>
            </b-field>

            <b-field :label="$t('invoice_code')" class="w-full lg:w-1/4 mr-4">
                <b-input v-model="invoiceCode" type="text" expanded></b-input>
            </b-field>

            <b-field :label="$t('invoice_date')" class="w-full lg:w-1/4 mr-4">
                <b-datepicker v-model="billingDate" :expanded="true" :editable="false" :clearable="true"
                    :placeholder="$t('date')" icon="calendar" icon-pack="fas" :icon-right="billingDate ? 'times' : ''"
                    icon-right-clickable @icon-right-click="clearBillingDate" trap-focus>
                </b-datepicker>
            </b-field>

            <b-field :label="$t('due_date')" class="w-full lg:w-1/4 mr-4">
                <b-datepicker v-model="dueDate" :expanded="true" :editable="false" :clearable="true"
                    placeholder="Vencimiento" icon="calendar" icon-pack="fas" :icon-right="dueDate ? 'times' : ''"
                    icon-right-clickable @icon-right-click="clearDueDate" trap-focus>
                </b-datepicker>
            </b-field>

            <b-field label="Total Facturado" class="w-full lg:w-1/4 mr-4">
                <b-input v-model="totalInvoiced" type="number" expanded disabled></b-input>
            </b-field>
        </div>
        <div class="flex justify-end">
            <v-button @click="openResourceModal(null)">{{
                $t('add_decomposed') }}</v-button>
        </div>
        <resource-modal ref="resource_modal" :resources="resources" :projectId="projectId" @reload-list="handleReload"
            @total_amount="handleTotalAmount" />
        <resources-table :projectId="projectId" ref="resources_table" @epc-edit="handleEditResource"
            @reload-list="handleReload" @total_amount="handleTotalAmount"></resources-table>
    </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from "vuex";
import ResourceModal from "../../components/cost-management/resource-modal.vue";
import ResourcesTable from "../../components/cost-management/resources.vue";
export default {
    components: {
        ResourceModal,
        ResourcesTable
    },
    data() {
        return {
            projectId: null,
            providers: [],
            selectedProvider: null,
            invoiceCode: '',
            totalInvoiced: null,
            billingDate: null,
            dueDate: null,
            resources: [],
        };
    },
    computed: {
        isFormValid() {
            return this.selectedProvider !== null && this.invoiceCode !== '' && this.totalInvoiced !== null;
        },
        ...mapGetters({
            navData: "app/navData"
        })
    },
    created() {
        this.projectId = this.navData.project_id;
        this.getProviders();
    },
    methods: {
        handleReload() {
            this.$refs.resources_table.reload({ provider: this.providers[this.selectedProvider].provider_id, code: this.invoiceCode });
        },
        async getProviders() {
            try {
                const { data } = await axios.get(`/api/v2/project/${this.projectId}/providers`);
                if (data && data.success) {
                    this.providers = data.providers;
                }
            } catch (error) {
                throw ('Error fetching providers:', error);
            }
        },
        async getProviderProducts(providerId) {
            try {
                const response = await axios.get(`/api/v2/invoices/provider/${providerId}/${this.projectId}`);
                this.resources = response.data.products;
            } catch (error) {
                throw new Error('Error fetching provider products: ' + error);
            }
        },
        async getProductsInvoce(providerId, invoiceCode) {
            try {
                const response = await axios.get(`/api/v2/invoices/provider/${providerId}/products`, {
                    params: {
                        provider_id: providerId,
                        code: invoiceCode
                    }
                });
                this.resources = response.data.products;
            } catch (error) {
                throw new Error('Error fetching provider products: ' + error);
            }
        },
        async openResourceModal(resource = null) {
            if (this.selectedProvider === null) {
                this.$toast.open({
                    message: 'Selecciona un proveedor primero',
                    type: "is-danger",
                    position: "is-top-right"
                });
                return;
            }
            if (!this.invoiceCode && resource === null) {
                this.$toast.open({
                    message: 'Ingresa un código de factura',
                    type: "is-danger",
                    position: "is-top-right"
                });
                return;
            }
            if (!this.billingDate && resource === null) {

                this.$toast.open({
                    message: 'Ingresa la fecha de la factura',
                    type: "is-danger",
                    position: "is-top-right"
                });
                return;
            }
            if (!this.dueDate && resource === null) {
                this.$toast.open({
                    message: 'Ingresa la fecha de vencimiento de la factura',
                    type: "is-danger",
                    position: "is-top-right"
                });
                return;
            }

            this.processingData = true;
            try {
                await this.getProviderProducts(this.providers[this.selectedProvider].provider_id);
                
                this.showResourceModal(resource);
            } catch (error) {
                this.$toast.open({
                    message: 'Error al verificar la factura',
                    type: 'is-danger',
                    position: 'is-top-right'
                });
                throw ('Error verifying invoice:', error);
            } finally {
                this.processingData = false;
            }
        },
        showResourceModal(resource) {
            this.$refs.resource_modal.show({
                resource,
                selectedProvider: this.providers[this.selectedProvider],
                invoiceCode: this.invoiceCode,
                billingDate: this.billingDate,
                dueDate: this.dueDate,
                totalInvoiced: this.totalInvoiced,
            });
        },
        handleEditResource(resource) {
            this.openResourceModal(resource);
        },
        handleTotalAmount(total) {
            this.totalInvoiced = total;
        },
        goToPage(destiny) {
            switch (destiny) {
                case 'cost_budget':
                    this.$router.push({
                        name: 'project.admin.cost-management',
                        params: {
                            project_id: this.projectId,
                        },
                        query: {
                            tab: 'billing' // Parámetro de query para indicar el tab a abrir
                        }
                    });
                    break;
            }
        },
        clearBillingDate() {
            this.billingDate = null;
        },
        clearDueDate() {
            this.dueDate = null;
        }
    },
    watch: {
        selectedProvider(newProvider) {
            if (newProvider !== null && this.invoiceCode) {
                this.handleReload();
            }
        },
        invoiceCode(newCode) {
            if (newCode && this.selectedProvider !== null) {
                this.handleReload();
            }
        },
        billingDate(newVal) {
            if (newVal && this.dueDate && new Date(newVal) > new Date(this.dueDate)) {
                this.$buefy.toast.open({
                    message: 'La fecha de factura no puede ser mayor que la fecha de vencimiento.',
                    type: 'is-danger',
                    duration: 5000,
                });
                this.billingDate = null;
            }
        },
        dueDate(newVal) {
            if (newVal && this.billingDate && new Date(this.billingDate) > new Date(newVal)) {
                this.$buefy.toast.open({
                    message: 'La fecha de vencimiento no puede ser menor que la fecha de factura.',
                    type: 'is-danger',
                    duration: 5000,
                });
                this.dueDate = null;
            }
        }
    },
};
</script>

<style lang="scss" scoped></style>
