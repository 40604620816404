<template>
  <div class="page-with-top-bar">
    <project-admin-quick-buttons hiddenOption="partners" />
      <section>
        <div class="mt-4">
          <b-tabs type="is-boxed" v-model="mainActiveTab">
            <b-tab-item>
              <template slot="header">
                <b-icon pack="fas" icon="file-import"></b-icon>
                <span> {{ $t('partners') }} </span>
              </template>
              <div>
                <ProjectPartners></ProjectPartners>
              </div>
            </b-tab-item>

            <b-tab-item>
              <template slot="header">
                <b-icon pack="fas" icon="file-export"></b-icon>
                <span> {{ $t('providers') }} </span>
              </template>
              <div>
                <projectProviders></projectProviders>
              </div>
            </b-tab-item>
          </b-tabs>
        </div>
      </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProjectAdminQuickButtons from '·/components/project-admin-quick-buttons'
import projectProviders from './partners/project-providers.vue'
import ProjectPartners from './partners/project-partners.vue'

export default {
  middleware: 'auth',
  title: 'partners_providers_page_title',
  subtitle: 'partners_providers_page_subtitle',
  menuOption: '0-2',

  components: {
    ProjectAdminQuickButtons,
    projectProviders,
    ProjectPartners 
  },

  computed: {
    ...mapGetters({
      navData: 'app/navData',
    })
  },

  data: () => ({
    mainActiveTab: 0,
    project_id: null,
  }),

  created() {
    this.project_id = parseInt(this.navData.project_id)
  }
}
</script>