<template>
  <b-modal :active.sync="isModalActive" :width="1028" scroll="clip" :can-cancel="canCancel" @close="closeModal">

    <div class="border border-blue-light rounded shadow bg-white p-4">
      <form @submit.prevent="saveStoreOut" class="w-full">
        <header class="font-thin uppercase text-blue-light modal-card-head_ mb-4"
        >Registrar salida de material</header>

        <div class="modal-card-body_ relative">
            <div class="flex flex-row border-grey-light border-b border-dashed mb-3">
              <div class="w-2/4 flex flex-col mb-3">

                <div class="w-full mb-3">
                  <b-field :label="$t('material')">
                    <!-- <multiselect v-model="product" :options="products"
                      :custom-label="nameWithCode"
                      :placeholder="$t('select')"
                      label="name"
                      track-by="name"
                      :show-labels="false"
                      :maxHeight="200"
                      @input="inputChangeMaterial"/> -->
                    <multiselect v-model="product" :options="filteredProducts"
                      :custom-label="nameWithCode"
                      :placeholder="$t('select_or_type_to_search')"
                      label="name"
                      track-by="name"
                      :show-labels="false"
                      :searchable="true"
                      :loading="isLoadingProducts"
                      @search-change="handleSearchChangeProducts"
                      :hide-selected="true"
                      @input="handleInputProducts"
                      :maxHeight="200"/>
                  </b-field>
                </div>

                <div class="w-full mb-3">
                  <b-field :label="$t('provider')">
                    <!-- <multiselect v-model="provider" :options="providers"
                      :custom-label="nameWithCode"
                      :placeholder="$t('select')"
                      label="name"
                      track-by="name"
                      :show-labels="false"
                      :maxHeight="200"
                      @input="inputChangeProvider"/> -->

                    <multiselect v-model="entry"
                      label="provider_name"
                      track-by="id"
                      :options="entries"
                      :placeholder="$t('select_or_type_to_search')"
                      :option-height="104"
                      :custom-label="customLabel"
                      @input="(value, id) => { form.price = value.unit_price; qtyFilter = value.available }"
                      :loading="isLoadingEntries"
                      :hide-selected="true"
                      :show-labels="false">
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc"><span class="option__title">{{ props.option.provider_name }}</span></span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <p class="text-gray-900 font-bold text-md">{{ props.option.provider_name }}</p>
                          <p class="text-sm text-gray-400 flex items-center">
                            <span class="mr-3"><b-icon pack="fas" icon="calendar"></b-icon> {{ props.option.date_purchase }}</span>
                            <span class="mr-3"><b-icon pack="fas" icon="money-bill"></b-icon> {{ props.option.unit_price }}</span>
                            <span class="mr-3"><b-icon pack="fas" icon="box"></b-icon> {{ props.option.qty }}</span>
                            <span class="mr-3"><b-icon pack="fas" icon="box-open"></b-icon> {{ props.option.used_qty }}</span>
                            <span class=""><b-icon pack="fas" :icon="'thumbs-' + ( props.option.available > 0 ? 'up' : 'down')"></b-icon> {{ props.option.available }}</span>
                          </p>
                        </div>
                      </template>
                    </multiselect>
                  </b-field>
                </div>

                <div class="w-full mb-3" v-if="false">
                  <b-field :label="$t('activity')">
                    <multiselect v-model="entity" :options="entities"
                      :custom-label="nameWithCode"
                      :placeholder="$t('select')"
                      label="name"
                      track-by="name"
                      :show-labels="false"
                      :maxHeight="200"/>
                  </b-field>
                </div>

              </div>

              <div class="w-1/4 flex flex-col mb-3 justify-center items-center">
                <div class="text-center">
                  <p class="font-bold">Inventario existente</p>
                  <p><span class="font-bold text-2xl text-blue-light">{{ showQuantity(totalInventory) }}</span></p>
                </div>
                <div class="text-center">
                  <p class="font-bold">Inventario filtrado</p>
                  <p><span class="font-bold text-2xl text-blue-light">{{ showQuantity(qtyFilter) }}</span></p>
                </div>
              </div>

              <div class="w-1/4 flex flex-col mb-3">

                <div class="w-full mr-4">
                  <b-field :label="$t('date_out')">
                    <b-datepicker
                      v-model="form.date_out"
                      :show-week-number="false"
                      :locale="'es-ES'"
                      icon-pack="fas"
                      placeholder="Fecha de salida..."
                      icon="calendar-alt"
                      :icon-right="form.date_out ? 'close-circle' : ''"
                      icon-right-clickable
                      @icon-right-click="() => { form.date_out = null }"
                      trap-focus>
                    </b-datepicker>
                  </b-field>
                </div>

                <div class="w-full mr-4">
                  <b-field :label="$t('quantity')">
                    <v-input
                      v-model="form.qty"
                      title="quantity"
                      required="true"
                      name="quantity"
                      placeholder="quantity"
                      maxlength="100"
                      class="mr-1"
                    />
                  </b-field>
                </div>

                <div class="w-full mr-4">
                  <b-field :label="$t('unit_price')">
                    <v-input
                      v-model="form.price"
                      title="unit_price"
                      disabled
                      icon="money-bill"
                      pack="fas"
                      name="price"
                      placeholder="price"
                      maxlength="100"
                    />
                  </b-field>
                </div>

                <div class="w-full">
                  <b-field :label="$t('delivery_note')">
                    <v-input
                      v-model="form.delivery_note"
                      title="delivery_note"
                      name="delivery_note"
                      placeholder="delivery_note"
                      maxlength="100"
                    />
                  </b-field>
                </div>

              </div>

              <b-loading :is-full-page="false" v-model="processingData" :can-cancel="false"></b-loading>
            </div>
        </div>
        <footer class="modal-card-foot_">
          <div class="w-full inline-flex justify-end">
            <!-- <v-button color="grey" icon="undo-alt" @click="isModalActive = false;">{{ $t('cancel') }}</v-button> -->
            <!-- <button class="button" size="is-small" type="button" @click="isModalActive = false; /*$emit('update:show', !show)*/">{{ $t('cancel') }}</button> -->
            <button class="btn btn-blue" nativeType="submit" v-if="!processingData">{{$t('save')}}</button>
            <v-button-processing v-if="processingData" />
          </div>
        </footer>
      </form>
    </div>
  </b-modal>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import moment from 'moment'
import Multiselect from 'vue-multiselect'
import { parseQuantity } from './helpers.js'

export default {
  name: "outs-modal",

  components: {
    Multiselect
  },

  props: {
    projectId: { type: Number, required: true },
    entityId: { type: Number, required: true }, // identificador del tipo de entidad activity, issue, resourse, task, flow, etc
    entityType: { type: String, required: true }, // nombre del tipo de entidad activity, issue, resourse, task, flow, etc
  },

  data: () => ({
    isModalActive: false,
    processingData: false,
    canCancel: ['escape', 'x', 'outside'],
    data: null,
    form: {
      id: null,
      product_id: null,
      provider_id: null,
      entity_id: null,
      entity_type: null,
      date_out: null, // new Date(),
      moment_date_out: null,
      qty: "0.00",
      price: "0.00",
      delivery_note: "",
    },
    options: [
      { value: 0, text: 'Sin categoría' }
    ],

    product: null,
    products: [],
    filteredProducts: [],
    isLoadingProducts: false, // Indicador de carga de productos

    provider: null,
    providers: [],
    filteredProviders: [],
    isLoadingProviders: false, // Indicador de carga de proveedores

    entity: null, // entidad a la cual se esta asignando la salida (actividad, incidencia, recurso, etc)
    entities: [], // lista de las entidades a seleccion

    inventories: [],
    inventory: null,

    totalInventory: 0,
    qtyFilter: 0,

    ajaxTimer: null,

    entry: null,
    entries: [],
    isLoadingEntries: false,
  }),

  computed: {
      ...mapGetters({
        company: "app/company",
      }),
  },

  created() {
    this.getProductsProject()
    // this.getProvidersProject() // esto hay que cargar cada vez que se cambia de producto
    // this.getEtitiesProject() // esto no sera necesario cargar por que ya se esta pasando entityId and entityType en este contexto para unificar codigo tomar en cuanta
  },

  methods: {
    nameWithCode ({ name, code }) {
      return `${name} — [${code}]`
    },

    customLabel ({ id, provider_name, provider_id }) {
      return `${provider_name} – ${id} – ${provider_id}`
    },

    async getProductsProject(q=null) {
      let url = '/api/v2/project/'+this.projectId+'/store-products'
      let filters = { filter_name: q }

      const {data} = await axios.get(url, { params: filters })
      if (data && data.success) {
        this.filteredProducts = data.products;
        this.isLoadingProducts = false;
      } else {
        this.$notify.error('error_retreiving_measures')

      }
    },

    async getProvidersProject() {
      this.providers = []
      let params = {
        company_id: this.company.id
      }

      const {data} = await axios.get('/api/v2/providers', { params: params })
      if (data && data.success) {
        this.providers = data.providers
      } else {
        this.$notify.error('error_retreiving_measures')

      }
    },

    async inputChangeMaterial(value, id) {
      this.providers = []
      this.inventory = null;

      let params = {
        product_id: this.product.id
      }

      const {data} = await axios.get('/api/v2/project/'+this.projectId+'/store-inventories', { params: params })
      if (data && data.success) {
        this.inventories = data.products
        this.providers = data.products.map((item) => ({ id: item.provider.id, name: item.provider.name, code: item.provider.code }))
      } else {
        this.$notify.error('error_retreiving_measures')
      }
    },

    handleInputProducts(value, id) {
      this.entries = []
      this.entry = null
      this.getWarehouseEntries()
    },

    handleSearchChangeProducts(search) {
      if (search.length < 3) {
        // Si la búsqueda tiene menos de 3 caracteres, mostrar todos los elementos locales
        this.filteredProducts = this.products;
      } else {
        // Realizar búsqueda local
        this.filteredProducts = this.products.filter(option =>
          option.name.toLowerCase().includes(search.toLowerCase())
        );

        if (this.filteredProducts.length === 0) {
          // No se encontraron resultados locales, realizar búsqueda remota
          clearTimeout(this.ajaxTimer);
          this.ajaxTimer = setTimeout(() => {
            this.isLoadingProducts = true;
            this.getProductsProject(search);
          }, 500);
        }
      }
    },

    inputChangeProvider(value, id) {
      this.inventory = this.inventories.find((item) => item.product_id == this.product.id && item.provider_id == this.provider.provider_id );
    },

    async getEtitiesProject() {
      this.entities = []

      let apiEntity = this.getApiEntity();

      const {data} = await axios.get(apiEntity.url, { 'params': apiEntity.params })
      if (data && data.success) {
        this.entities = this.getDataEntity(data)
      } else {
        this.$notify.error('error_retreiving_measures')

      }
    },

    getApiEntity(){
      let api = {
        url: '/api/v2/',
        params: {}
      }
      switch (this.entityType) {
        case 'activity':
          api.url += 'activities'
          api.params = { p : this.projectId, with_folders: 1, per_page: 999 }
          break;

        default:
          api += 'notapi'
          break;
      }

      return api;
    },

    getDataEntity(response){
      let entities = []
      if( this.entityType == 'activity' ) {
        response.activities.forEach(item => {
          entities.push({ id: item.id, name: item.name, type: 'activity', code: item.planning_code })
        });
      }

      return entities;
    },

    async saveStoreOut() {
      if( parseFloat(this.form.qty) > parseFloat(this.entry.available) ) {
        this.$notify.error('La cantidad solicitada en mayor a la cantidad filtrada en inventario')
        return
      }

      let url = "/api/v2/project/"+this.projectId+"/store-out";
      if( this.form.id)
        url = "/api/v2/store-out/" + this.form.id;

      this.form.product_id = this.product.id
      // this.form.provider_id = this.provider.id
      this.form.provider_id = this.entry.provider_id
      this.form.purchase_id = this.entry.id

      // this.form.entity_id = this.entity.id
      // this.form.entity_type = this.entityType
      this.form.entity_id = this.entityId
      this.form.entity_type = this.entityType

      if( this.form.date_out != null)
        this.form.moment_date_out = moment(this.form.date_out).format('YYYY-MM-DD')


      this.canCancel = [];
      this.processingData = true
      axios({
        method: "post",
        url: url,
        data: this.form
      })
        .then(response => {
          this.$toast.open({
            message: this.$t("save_successful"),
            type: "is-success",
            position: "is-top-right"
          });

          this.processingData = false
          this.canCancel = ['escape', 'x', 'outside']

          this.hide();
          this.$emit("reload-list");
        })
        .catch(error => {
          //Se produjo un error
          this.isLoading = false;
          this.processingData = false
          this.canCancel = ['escape', 'x', 'outside']
          if (error.response && error.response.status === 422) {
            //extraemos el primer error de validación
            error.error_msg =
              error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ][0];
          }

          // Mostramos el error
          this.$toast.open({
            message: error.error_msg || this.$t("save_error"),
            type: "is-danger",
            position: "is-top-right"
          });
        });
    },

    show(data) {
      this.data = data;
      this.form.id = data.id;
      this.form.product_id = data.product_id;
      this.form.provider_id = data.provider_id;
      this.form.entity_id = data.entity_id;
      // this.form.date_purchase = moment(new Date(this.data.date_purchase) , 'YYYY-MM-DD')//.format("DD/MM/YYYY"),
      this.form.date_out = this.data.date_out ? new Date(this.data.date_out) : null;
      this.form.qty = data.qty.toString(),
      this.form.price = data.price.toString();
      this.form.delivery_note = data.delivery_note;

      this.product = null
      this.provider = null

      if( this.form.product_id ) {
          this.product = this.products.find((item) => item.id == this.form.product_id);
      }

      if( this.form.provider_id ) {
          this.provider = this.providers.find((item) => item.id == this.form.provider_id);
      }

      if( this.form.entity_id ) {
          this.entity = this.entities.find((item) => item.id == this.form.entity_id);
      }

      this.isModalActive = true;
    },

    hide() {
      this.data = null;
      this.product = null
      this.provider = null

      this.entry = null
      this.entries = []

      this.qtyFilter = 0
      this.totalInventory = 0

      this.isModalActive = false;
      this.form.id = null;
      this.form.product_id = null;
      this.form.provider_id = null;
      this.form.date_purchase = null, // new Date()
      this.form.qty = "0.00",
      this.form.price = "0.00";
      this.form.delivery_note = "";

    },

    closeModal() {
      this.$emit('update:show', !this.show)
    },

    async getWarehouseEntries() {
      this.isLoadingEntries = true
      this.purchases = []

      let filters = {
        filter_product_id: this.product ? this.product.id : null,
        // with_outs: 1,
        with_total_outs: 1
      }

      const {data} = await axios.get(`/api/v2/project/${this.projectId}/store-purchases`, {params: filters})
      if (data && data.success) {
        this.totalInventory = data.total_inventory
        let entries = []
        data.purchases.forEach(item => {

          let entry = {
            id: item.id,
            date_purchase: item.date_purchase,
            unit_price: item.unit_price,
            qty: item.qty,
            provider_id: item.inventory.provider.provider_id,
            provider_name: item.inventory.provider.name,
            used_qty: item.outs_sum_qty == null ? 0 : item.outs_sum_qty,
            available: item.qty - (item.outs_sum_qty == null ? 0 : item.outs_sum_qty)
          }

          entries.push(entry)
        })

        this.entries = entries

        this.isLoadingEntries = false
      }
    },

    showQuantity(quantity) {
      return parseQuantity(quantity);
    },
  }
};
</script>
<style scoped>
/* Oculta los botones para aumentar los inputs numéricos */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.modal .animation-content.modal-content,
.modal .animation-content .modal-card {
  overflow: visible !important;
}

.modal-card-body {
  overflow: visible !important;
}

::v-deep .modal-content {
  overflow: visible !important;
}

</style>
