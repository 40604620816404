<template>
  <b-modal :active.sync="isModalActive" :width="1028" scroll="keep" :can-cancel="canCancel" @close="closeModal">

    <div class="border border-blue-light rounded shadow bg-white p-4">
      <form @submit.prevent="saveInventory" class="w-full">
        <header class="font-thin uppercase text-blue-light modal-card-head_ mb-4">Añadir / editar entrada</header>

        <div class="modal-card-body_ relative">
          <div class="border-grey-light border-b border-dashed mb-3">

            <div class="w-full mb-3">
              <b-field :label="$t('material')">
                <multiselect v-model="product" :options="filteredProducts" :custom-label="nameWithCode"
                  :placeholder="$t('select') + ' escribe lo que estas buscando'" :multiple="false" label="name"
                  track-by="name" :show-labels="false" :searchable="true" :loading="isLoadingProducts"
                  noOptions="La lista esta vacía" @search-change="handleSearchChangeProducts" :maxHeight="200" />
              </b-field>
            </div>

            <div class="w-full mb-3">
              <b-field :label="$t('provider')">
                <multiselect v-model="provider" :options="providers" :custom-label="nameWithCode"
                  :placeholder="$t('select')" label="name" track-by="name" :show-labels="false"
                  @input="_HandleInputProvider" :maxHeight="200" />
              </b-field>
            </div>

            <div class="w-full flex flex-row mt-4 mb-3">

              <div class="w-2/5 mr-4">
                <b-field :label="$t('date_purchase')">
                  <b-datepicker v-model="form.date_purchase" :show-week-number="false" :locale="'es-ES'" icon-pack="fas"
                    placeholder="Fecha compra..." icon="calendar-alt"
                    :icon-right="form.date_purchase ? 'close-circle' : ''" icon-right-clickable
                    @icon-right-click="() => { form.date_purchase = null }" required="true" trap-focus>
                  </b-datepicker>
                </b-field>
              </div>

              <div class="w-1/5 mr-4">
                <b-field :label="$t('quantity')">
                  <v-input v-model="form.qty" title="quantity" required="true" name="quantity" placeholder="quantity"
                    maxlength="100" />
                </b-field>
              </div>

              <div class="w-1/5 mr-4">
                <b-field :label="$t('unit_price')">
                  <!-- <v-input
                      v-model="form.unit_price"
                      title="unit_price"
                      required="true"
                      name="unit_price"
                      placeholder="unit_price"
                      maxlength="100"
                    /> -->
                  <multiselect v-model="unitPrice" tag-placeholder="Añadir precio"
                    :placeholder="unitPrice.price.toString()" label="price" track-by="price" :options="prices"
                    :multiple="false" :taggable="true" :show-labels="false" :showNoOptions="false" @tag="addTag">
                  </multiselect>
                </b-field>


              </div>

              <!-- <div class="w-1/5 mr-4">
                  <b-field :label="$t('price')">
                    <v-input
                      v-model="form.price"
                      title="price"
                      required="true"
                      name="price"
                      placeholder="price"
                      maxlength="100"
                    />
                  </b-field>
                </div> -->

              <div class="w-5/5">
                <b-field :label="$t('delivery_note')">
                  <v-input v-model="form.delivery_note" title="delivery_note" required="false" name="delivery_note"
                    placeholder="delivery_note" maxlength="100" />
                </b-field>
              </div>

            </div>

            <b-loading :is-full-page="false" v-model="processingData" :can-cancel="false"></b-loading>
          </div>
        </div>
        <footer class="modal-card-foot_">
          <div class="w-full inline-flex justify-end">
            <!-- <v-button color="grey" icon="undo-alt" @click="isModalActive = false;">{{ $t('cancel') }}</v-button> -->
            <!-- <button class="button" size="is-small" type="button" @click="isModalActive = false; /*$emit('update:show', !show)*/">{{ $t('cancel') }}</button> -->
            <button class="btn btn-blue" nativeType="submit" v-if="!processingData">{{ $t('save') }}</button>
            <v-button-processing v-if="processingData" />
          </div>
        </footer>
      </form>
    </div>
  </b-modal>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import moment from 'moment'
import Multiselect from 'vue-multiselect'

export default {
  name: "material-modal",

  components: {
    Multiselect
  },

  props: {
    projectId: { type: Number, required: true },
    products: { type: Array, default: () => ([]) },
    providers: { type: Array, default: () => ([]) },
  },

  data: function () {
    return {
      isModalActive: false,
      processingData: false,
      canCancel: ['escape', 'x', 'outside'],
      data: null,
      form: {
        id: null,
        product_id: null,
        provider_id: null,
        date_purchase: null, // new Date(),
        moment_date_purchase: null,
        qty: "0.00",
        unit_price: "0.00",
        price: "0.00",
        delivery_note: "",
      },
      options: [
        { value: 0, text: 'Sin categoría' }
      ],

      product: null,
      filteredProducts: [],
      isLoadingProducts: false, // Indicador de carga de productos
      provider: null,
      filteredProviders: this.providers,
      isLoadingProviders: false, // Indicador de carga de proveedores

      ajaxTimer: null,

      unitPrice: { price: 0 },
      prices: []
    }
  },

  computed: {
    ...mapGetters({
      company: "app/company",
    }),
  },

  created() {
    this.filteredProducts = this.products
    // this.getProductsProject()
    // this.getProvidersProject()
  },

  methods: {
    async _HandleInputProvider(value, id) {
      // console.log('change input produdto value: ', value)
      let url = '/api/v2/project/' + this.projectId + '/store-purchases'
      // console.log('url: ', value.id, " - ", value.provider_id)
      let filters = {
        filter_provider_id: value.provider_id,
        filter_product_id: this.product ? this.product.id : 0, // sino seleciono producto/material mandamos 0
        only_price: 1
      }

      const { data } = await axios.get(url, { params: filters })
      if (data && data.success) {
        this.prices = data.purchases;
      } else {
        this.$notify.error('error_retreiving_measures')
      }
    },

    addTag(newTag) {
      const tag = {
        price: newTag,
        // code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.prices.push(tag)
      // this.unitPrice.push(tag)
      this.unitPrice = tag
    },

    nameWithCode({ name, code }) {
      return `${name} — [${code}]`
    },

    async getProductsProject(q = null) {
      let url = '/api/v2/project/' + this.projectId + '/store-products'
      let filters = { filter_name: q }

      const { data } = await axios.get(url, { params: filters })
      if (data && data.success) {
        this.filteredProducts = data.products;
        this.isLoadingProducts = false;
      } else {
        this.$notify.error('error_retreiving_measures')

      }
    },

    async getProvidersProject() {
      this.providers = []

      const { data } = await axios.get('/api/v2/project/' + this.projectId + '/providers')
      if (data && data.success) {
        this.providers = data.providers
      } else {
        this.$notify.error('error_retreiving_measures')

      }
    },

    async saveInventory() {
      let url = "/api/v2/project/" + this.projectId + "/store-purchase";
      if (this.form.id)
        url = "/api/v2/store-purchase/" + this.form.id;

      this.form.product_id = this.product.id
      this.form.provider_id = this.provider.provider_id
      this.form.unit_price = this.unitPrice.price

      this.form.moment_date_purchase = moment(this.form.date_purchase).format('YYYY-MM-DD')


      this.canCancel = [];
      this.processingData = true
      axios({
        method: "post",
        url: url,
        data: this.form
      })
        .then(response => {
          this.$toast.open({
            message: this.$t("save_successful"),
            type: "is-success",
            position: "is-top-right"
          });

          this.processingData = false
          this.canCancel = ['escape', 'x', 'outside']

          this.hide();
          this.$emit("reload-list");
        })
        .catch(error => {
          //Se produjo un error
          this.isLoading = false;
          this.processingData = false
          this.canCancel = ['escape', 'x', 'outside']
          if (error.response && error.response.status === 422) {
            //extraemos el primer error de validación
            error.error_msg =
              error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
              ][0];
          }

          // Mostramos el error
          this.$toast.open({
            message: error.error_msg || this.$t("save_error"),
            type: "is-danger",
            position: "is-top-right"
          });
        });
    },

    handleSearchChangeProducts(search) {
      if (search.length < 3) {
        // Si la búsqueda tiene menos de 3 caracteres, mostrar todos los elementos locales
        this.filteredProducts = this.products;
      } else {
        // Realizar búsqueda local
        this.filteredProducts = this.products.filter(option =>
          option.name.toLowerCase().includes(search.toLowerCase())
        );
        if (this.filteredProducts.length === 0) {
          // No se encontraron resultados locales, realizar búsqueda remota
          clearTimeout(this.ajaxTimer);
          this.ajaxTimer = setTimeout(() => {
            this.isLoadingProducts = true;
            this.getProductsProject(search);
          }, 500);
        }
      }
    },

    performRemoteSearch(search) {
      // Realizar la consulta AJAX remota utilizando 'search' como parámetro
      // ...
      // Luego, asignar los resultados obtenidos al arreglo this.filteredOptions y desactivar isLoading
      // this.filteredOptions = results;
      // this.isLoading = false;
    },

    show(data) {
      this.data = data;
      this.form.id = data.id;
      this.form.product_id = data.product_id;
      this.form.provider_id = data.provider_id;
      // this.form.date_purchase = moment(new Date(this.data.date_purchase) , 'YYYY-MM-DD')//.format("DD/MM/YYYY"),
      this.form.date_purchase = this.data.date_purchase ? new Date(this.data.date_purchase) : null;
      this.form.qty = data.qty.toString(),
        this.form.unit_price = data.unit_price.toString(),
        this.form.price = data.price.toString();
      this.form.delivery_note = data.delivery_note;

      this.product = null
      this.provider = null

      if (this.form.product_id) {
        this.product = this.products.find((item) => item.id == this.form.product_id);
      }

      if (this.form.provider_id) {
        this.provider = this.providers.find((item) => item.id == this.form.provider_id);
      }

      this.isModalActive = true;
    },

    hide() {
      this.data = null;
      this.product = null
      this.provider = null

      this.isModalActive = false;
      this.form.id = null;
      this.form.product_id = null;
      this.form.provider_id = null;
      this.form.date_purchase = null, // new Date()
        this.form.qty = "0.00",
        this.form.unit_price = "0.00",
        this.form.price = "0.00";
      this.form.delivery_note = "";
    },

    closeModal() {
      this.$emit('update:show', !this.show)
    }
  }
};
</script>
<style scoped>
/* Oculta los botones para aumentar los inputs numéricos */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
}

::v-deep .modal-content {
  overflow: visible !important;
}
</style>
