<template>
  <div class="page-with-top-bar">

    <div class="panel">
      <div v-if="!loaded" class="text-center my-4">
        <scale-loader></scale-loader>
      </div>
      <div v-else>
        <div class="w-full flex flex-wrap mt-4">

          <section class="w-full mt-4">
            <label class="font-bold">{{ $t('partners_linked_with_project') }} '{{ navData.project_name }}':</label>
            <p><br></p>
            <b-table
            :data="projectPartners"
            :paginated="isPaginated"
            :per-page="perPage"
            :bordered="true"
            :current-page.sync="currentPage"
            :pagination-simple="isPaginationSimple"
            :default-sort-direction="defaultSortDirection"
            icon-pack="fas"
            v-if="loaded">
            <template>
              <b-table-column field="name" sortable>
                <template v-slot:header="">{{ $t('name') }}</template>
                <template v-slot="props">{{ props.row.name }}</template>
              </b-table-column>

              <b-table-column field="code" sortable>
                <template v-slot:header="">{{ $t('code') }}</template>
                <template v-slot="props">{{ props.row.code }}</template>
              </b-table-column>

              <b-table-column field="email" sortable>
                <template v-slot:header="">{{ $t('email') }}</template>
                <template v-slot="props">{{ props.row.email }}</template>
              </b-table-column>

              <b-table-column field="cif" sortable>
                <template v-slot:header="">{{ $t('cif') }}</template>
                <template v-slot="props">{{ props.row.cif }}</template>
              </b-table-column>

              <b-table-column field="type" sortable>
                <template v-slot:header="">{{ $t('type') }}</template>
                <template v-slot="props">{{ $t(types[props.row.type]) }}</template>
              </b-table-column>


              <b-table-column field="actions" width=100 sortable>
                <template v-slot:header="">{{ $t('actions') }}</template>
                <template v-slot="props">
                  <div class="w-full h-full flex items-center justify-around">

                    <b-tooltip :label="$t('purchase_order')" position="is-left" type="is-info">
                      <div @click="assignWorkOrders(props.row.id, props.row.name)"
                          class="cursor-pointer flex text-blue">
                        <b-icon pack="fas" icon="person-digging"/>
                      </div>
                    </b-tooltip>

                    <b-tooltip :label="$t('remove')" position="is-left" type="is-danger">
                      <div @click="unAssignPartner(props.row.id, props.row.name)"
                          class="cursor-pointer flex text-red">
                        <b-icon pack="fas" icon="trash-alt"/>
                      </div>
                    </b-tooltip>

                  </div>
                </template>
              </b-table-column>
            </template>

            <template slot="bottom-left">
                <b-icon pack="fas" class="flex text-blue" icon="folder"></b-icon>
                <b>{{ $t("total") }}</b>: {{ this.projectPartners.length }}
            </template>

            <template #empty>
                <div class="has-text-centered">{{ $t('no_results') }}</div>
            </template>

            </b-table>
          </section>

          <div class="flex-col mt-4">
            <label form="partners_id">{{ $t('assign_partners')}}:&nbsp;</label>
          </div>
          <div class="flex-col mt-4">
            <b-taginput
              v-model="partnersToAdd"
              :data="filteredPartners"
              autocomplete
              field="name"
              :placeholder="$t('select')"
              @typing="getFilteredPartners">
              <template v-slot="props">
                  {{props.option.name}}
              </template>
              <template #empty>
                  {{ $t('empty_partners') }}
              </template>
            </b-taginput>

          </div>
          <div class="flex-col mt-4">
            <button class="btn btn-blue h-8" nativeType="submit"
              :disabled="!partnersToAdd.length" @click="assignPartners">
              <b-icon icon="plus" pack="fas"/>
            </button>
          </div>

        </div>
      </div>
      </div>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  import axios from 'axios'
  import ProjectAdminQuickButtons from '·/components/project-admin-quick-buttons'
  import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'

  export default {
    middleware: 'auth',
    title: 'partners_page_title',
    menuOption: '0-2',

    components: {
      ProjectAdminQuickButtons,
      ScaleLoader
    },

    computed: {
      ...mapGetters({
        navData: "app/navData",
      }),
      projectId() {
        return parseInt(this.navData.project_id)
      },
    },

    data: () => ({
      projectPartners: [],
      isPaginated: true,
      isPaginationSimple: false,
      defaultSortDirection: 'asc',
      currentPage: 1,
      perPage: 20,
      loaded: false,
      allPartners: [],
      filteredPartners: [],
      partnersToAdd: [],
      types: ['partners_type_ute', 'partners_type_cliente', 'partners_type_subcontrata'],
    }),

    created() {
      let projectName = this.navData.project_name
        ? this.navData.project_name.toUpperCase()
        : "";
      this.$options.subtitle = ["project_actions_page_subtitle", [projectName]];

      this.getProjectPartners();
      this.getAllPartners();
    },

    methods: {

      // partners de este proyecto
      async getProjectPartners() {

        const { data } = await axios.get("/api/v2/project/" + this.navData.project_id + "/partners", {'params' : { }});

        if (data.data) {
          this.projectPartners = data.data;
          this.loaded = true
        }

      },

      // todos los partners (para añadir)
      async getAllPartners() {

        const { data } = await axios.get("/api/v2/partners", { params: {
          project_id: this.projectId
        }});
        if (data.data) {
          this.allPartners = data.data;
        }

      },

      // de todos, filtra los que cumplen string de busqueda (y no estaban ya vinculados)
      getFilteredPartners(text) {
        this.filteredPartners = this.allPartners.filter((option) => {
          return option.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
            &&
            !this.alreadyInserted(option)
        })
      },

      // comprueba si un partner está ya vinculado al proyecto
      alreadyInserted(partner) {
        return this.projectPartners.some((option) => option.id === partner.id )
      },

      async assignPartners() {

        let formData = new FormData();
        formData.append('partners', JSON.stringify(this.partnersToAdd));

        let self = this
        this.loaded = false
        let url = '/api/v2/project/' + this.projectId + '/partners/add'

        const { data } = await axios.post(url, formData)

        if (data.success) {
          this.$toast.open({
            message: 'Ok',
            type: 'is-success',
            position: 'is-top-right'
          })
          self.partnersToAdd = []
          self.getProjectPartners()
        } else {
          self.loaded = true
          // Error
          self.$toast.open({
            message: data.error, //self.$t('import_generic_error_msg'),
            type: 'is-danger',
            position: 'is-top-right'
          })
        }
      },

      unAssignPartner(partnerId, partnerName) {

        let self = this;
        this.$dialog.confirm({
          title: this.$t('unlink'),
          message: this.$t('unlink_partner_confirm_text', [partnerName]),
          confirmText: this.$t('delete'),
          type: 'is-danger',
          hasIcon: true,
          onConfirm: async () => {
            let url = '/api/v2/project/' + this.projectId + '/partners/remove'
            const {data} = await axios.post(url, { partnerId: partnerId })
            if (data && data.success) {
              this.loaded = false
              this.$notify.success('success_deleting_partner')
              self.getProjectPartners()
            } else {
              this.$notify.error(data.error)
            }
          }
        })
      },

      assignWorkOrders(partnerId, partnerName) {
        this.$router.push({
          name: 'project.admin.partners.workorders'
          , params: {
            project_id: this.projectId,
            partner_id: partnerId
          }})
      }

    }
  };
</script>
