<template>
<div>
  <b-collapse class="card filters-collapse" :open.sync="showFilters">
    <div slot="trigger" class="card-header filters-trigger">
      <p class="card-header-title">
        {{ $t('filters') }}
      </p>
      <a class="card-header-icon">
        <b-icon pack="fa" v-show="! showFilters" icon="plus-circle"/>
        <b-icon pack="fa" v-show="showFilters" icon="minus-circle"/>
      </a>
    </div>
    <div class="panel" style="border-width: 0">
      <div class="flex items-center mb-4">
        <div class="w-1/2 mr-4">
          <b-field :label="$t('search') + ' por fecha de compra/salida'">
            <b-datepicker
              v-model="rangeDate"
              :placeholder="'Selecione rango de fechas'"
              :month-names="$t('months_names')"
              :day-names="$t('days_names')"
              :first-day-of-week="parseInt($t('first_day_of_week'))"
              :readonly="false"
              range
              @input="applyFilters(1)"
            >
            </b-datepicker>
          </b-field>
        </div>

        <div class="w-full mr-4">
          <b-field :label="$t('search_by_material')">
            <multiselect v-model="filterProduct" :options="filteredProducts"
              :placeholder="$t('select_or_type_to_search')"
              label="name"
              track-by="id"
              :show-labels="false"
              open-direction="bottom"
              :searchable="true"
              :max-height="600"
              :loading="isLoadingProducts"
              @search-change="handleSearchChangeProducts"
              @input="applyFiltersProduct"
            />
          </b-field>
        </div>
      </div>

      <div class="flex items-center mb-4">

        <div class="w-1/2 mr-4">
          <b-field :label="$t('search_by_provider')">
             <multiselect v-model="filterProvider" :options="filteredProviders"
              :placeholder="$t('select_or_type_to_search')"
              label="name"
              track-by="id"
              :show-labels="false"
              open-direction="bottom"
              :searchable="true"
              :max-height="600"
              :loading="isLoadingProviders"
              @search-change="handleInputProviders"
              @input="applyFiltersProvider"
            />
          </b-field>
        </div>

        <div class="w-full mr-4" v-if="_visibleFilters.visibleFilterActivities">
          <b-field :label="$t('search_by_activity')">
            <!-- <b-select ref="input"
              v-model="filterActivityId"
              :placeholder="$t('activity')"
              icon="search"
              icon-pack="fas"
              @input="applyFilters(1)"
              expanded
            >
              <option v-for="option in activities"
                      :value="option['id']"
                      :key="'activity_' + option['id']"
              >
                {{ option['name'] }}
              </option>
            </b-select> -->
            <treeselect v-model="filterActivity"
              :multiple="false"
              :placeholder="$t('select_or_type_to_search')"
              :options="activities"
              @input="applyFiltersActivity"/>
            <!-- <multiselect v-model="filterActivity" :options="activities"
              :placeholder="'Buscar por actividad'"
              label="name"
              track-by="name"
              :show-labels="false"
              open-direction="bottom"
              :searchable="true"
              :max-height="600"
              @input="applyFiltersActivity"
            /> -->
          </b-field>
        </div>

      </div>

      <div class="flex items-center mb-4">
        <div class="w-1/3 mr-4" v-if="_visibleFilters.visibleFilterPurposes">
          <b-field :label="$t('search_by_purpose')">
            <b-select ref="input"
              v-model="filterPurposeId"
              :placeholder="$t('purpose')"
              icon="search"
              icon-pack="fas"
              @input="applyFilters(1)"
              expanded
            >
              <option v-for="option in purposes"
                      :value="option['id']"
                      :key="'purpose_' + option['id']"
              >
                {{ option['name'] }}
              </option>
            </b-select>
          </b-field>
        </div>

        <div class="w-1/3 mr-4" v-if="_visibleFilters.visibleFilterMainDiameter">
          <b-field :label="$t('search_by_main_diameter')">
            <b-input
              v-model="filterMainDiameter"
              :placeholder="$t('main_diameter')"
              type="search"
              icon="search"
              icon-pack="fas"
              icon-right="close-circle"
              icon-right-clickable
              @icon-right-click="() => { filterMainDiameter = null}"
              @input="applyFilters(1)">
            </b-input>
          </b-field>
        </div>

        <div class="w-1/3 mr-4" v-if="_visibleFilters.visibleFilterDeliveryNote">
          <b-field :label="$t('search_by_delivery_note')">
            <b-input
              v-model="filterDeliveryNote"
              :placeholder="$t('delivery_note')"
              type="search"
              icon="search"
              icon-pack="fas"
              icon-right="close-circle"
              icon-right-clickable
              @icon-right-click="() => { filterDeliveryNote = null}"
              @input="applyFilters(1)">
            </b-input>
          </b-field>
        </div>

        <div class="w-1/3 mr-4" v-if="_visibleFilters.visibleFilterTypeProduct">
          <b-field :label="$t('search_by_type_product')">

            <b-select ref="input"
              v-model="filterTypeProductId"
              :placeholder="$t('type_product')"
              icon="search"
              icon-pack="fas"
              @input="applyFilters(1)"
              expanded
            >
              <option v-for="option in typeProducts"
                      :value="option['id']" :key="'type_product_'+option['id']"
              >
                {{ option['name'] }}
              </option>
            </b-select>

          </b-field>
        </div>
        <div class="w-1/3 mr-4" v-if="_visibleFilters.visibleFilterWarehouse">
          <b-field :label="$t('search_by_warehouse')">
            <b-select ref="input"
              v-model="filterWarehouseId"
              :placeholder="$t('warehouse_location')"
              icon="search"
              icon-pack="fas"
              @input="applyFilters(1)"
              expanded
            >
              <option v-for="option in warehouses"
                      :value="option['id']"
                      :key="'warehouse_' + option['id']"
              >
                {{ option['name'] }}
              </option>
            </b-select>
          </b-field>
        </div>

        <div class="w-1/3 mr-4" v-if="_visibleFilters.visibleFilterStockMin">
          <b-field :label="$t('search_by_stockmin')">
            <b-select ref="input"
              v-model="filterStockMin"
              icon="search"
              icon-pack="fas"
              @input="applyFilters(1)"
              expanded
            >
              <option value="0">NO</option>
              <option value="1">SI</option>
            </b-select>
          </b-field>
        </div>
      </div>

      <button class="button is-primary btn-blue" @click="cleanFiltersClicked()">{{$t('clean_filters')}}</button>
    </div>
  </b-collapse>
  <!-- <span v-if="! showFilters && ! blankFilters" class="mx-2 font-bold cursor-pointer text-red" @click="cleanFiltersClicked()">
    {{$t('clean_filters')}} <b-icon icon="trash-alt" pack="fas" size="is-small" class="text-red"/>
  </span> -->
</div>
</template>

<script>
  import moment from "moment";
  import axios from "axios";
  import {mapGetters} from "vuex";
  import EventBus from '~/plugins/bus'
  import Multiselect from 'vue-multiselect'

  // import the component
  import Treeselect from '@riophae/vue-treeselect'
  // import the styles
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'

  export default {
    name: 'storehouse-filters',

    computed: {
      ...mapGetters({
        project: 'app/project',
        company: 'app/company',
      }),

      // true si todos los filtros estan vacios o con su valor por defecto
      blankFilters() {
        return this.filterProductId == 0
          && this.filterProviderId == 0
          && this.filterActivityId == 0
          && this.filterPurposeId == 0
          && this.filterWarehouseId == 0
          && this.filterTypeProductId == 0
          && this.filterStockMin == 0
          && this.rangeDate.length
        },
    },

    components: {
      Multiselect,
      Treeselect
    },

    props: {
      projectId: { type: Number, require:true },
      visibleFilters : { type: Object, default: () => ({}) },
      products: { type: Array, default: () => ([]) },
      providers: { type: Array, default: () => ([]) },
    },

    data: function() {
      return {
        showFilters: false,
        filterProduct: null,
        filterProvider: null,
        filterActivity: null,
        filterMainDiameter: null,
        filterDeliveryNote: null,
        filterPurposeId: 0,
        filterTypeProductId: 0,
        filterWarehouseId: 0,
        filterStockMin: 0,
        filterDatedStart: null,
        filterDateEnd: null,

        rangeDate: [],

        activities: [],
        purposes: [],

        extrafields: [],

        isLoadingProducts: false,
        filteredProducts: this.products,
        ajaxTimerProducts: null,

        isLoadingProviders: false,
        filteredProviders: this.providers,
        ajaxTimerProviders: null,

        isLoadingActivities: false,
        ajaxTimerActivities: null,

        typeProducts: [],
        warehouses: [],
      }},

    computed: {
      _visibleFilters() {
        let visibleFiltersDefault = {
          visibleFilterActivities: true,
          visibleFilterPurposes: true,
          visibleFilterMainDiameter: true,
          visibleFilterTypeProduct: true,
          visibleFilterWarehouse: true,
          visibleFilterDeliveryNote: false,
          visibleFilterStockMin: true
        }

        return { ...visibleFiltersDefault, ...this.visibleFilters }
      }
    },

    watch: {},

    mounted() {
      // this.getProducts();
      // this.getProviders();

      if( this._visibleFilters.visibleFilterActivities )
        this.getActivities();

      if( this._visibleFilters.visibleFilterPurposes
        || this._visibleFilters.visibleFilterTypeProduct
        || this._visibleFilters.visibleFilterWarehouse
      )
        this.getExtrafields();
    },

    methods: {
      clearAll() {
        this.filterTypeProductId = 0
      },

      generateFilters() {
        // let params = {p: this.projectId, s_num: 1};
        let params = { };

        //Filtro por producto
        if (this.filterProduct) {
          params.filter_product_id = this.filterProduct.id
        }

        //Filtro por proveedor
        if (this.filterProvider) {
          params.filter_provider_id = this.filterProvider.provider_id
        }

        //Filtro por actividad
        if (this.filterActivity) {
          // params.filter_activity_id = this.filterActivity.id
          params.filter_activity_id = this.filterActivity
        }

        //Filtro proposito
        if (this.filterPurposeId > 0) {
          params.filter_purpose_id = this.filterPurposeId
        }

        //Filtro por tipo producto
        if (this.filterTypeProductId > 0) {
          params.filter_type_product_id = this.filterTypeProductId
        }

        //Filtro por almacen
        if (this.filterWarehouseId > 0) {
          params.filter_warehouse_id = this.filterWarehouseId
        }

        //Filtro por diametro principal
        if (this.filterMainDiameter) {
          params.filter_main_diameter = this.filterMainDiameter
        }

        //Filtro por albaran
        if (this.filterDeliveryNote) {
          params.filter_delivery_note = this.filterDeliveryNote
        }

        //Filtro por albaran
        if (this.filterStockMin) {
          params.filter_stock_min = this.filterStockMin
        }

        //Filtro por fecha
        if ( this.rangeDate.length ) {
          params.filter_date_start = moment(this.rangeDate[0]).format('YYYY-MM-DD')
          params.filter_date_end = moment(this.rangeDate[1]).format('YYYY-MM-DD')
        }

        return params
      },

      handleSearchChangeProducts(search) {
        if (search.length < 3) {
          // Si la búsqueda tiene menos de 3 caracteres, mostrar todos los elementos locales
          this.filteredProducts = this.products;
        } else {
          // Realizar búsqueda local
          this.filteredProducts = this.products.filter(option =>
            option.name.toLowerCase().includes(search.toLowerCase())
          );

          if (this.filteredProducts.length === 0) {
            // No se encontraron resultados locales, realizar búsqueda remota
            clearTimeout(this.ajaxTimerProducts);
            this.ajaxTimerProducts = setTimeout(() => {
              this.isLoadingProducts = true;
              this.getProducts(search);
            }, 500);
          }
        }
      },

      /**
       * Carga los estados personalizados
       * @returns {Promise<void>}
       */
      async getProducts(q = null) {
        this.isLoadingProducts = true
        let params = {
          filter_name: q
        }
        const {data} = await axios.get('/api/v2/project/' + this.projectId + '/store-products', { params: params});
        if (data && data.success) {
          this.filteredProducts = data.products;
          this.isLoadingProducts = false
          // EventBus.$emit('statusListReceived', this.statusList);
        } else {
          this.isLoadingProducts = false
          this.$notify.error('error_loading_products_data')
        }
      },

      handleInputProviders(search) {
        if (search.length < 3) {
          // Si la búsqueda tiene menos de 3 caracteres, mostrar todos los elementos locales
          this.filteredProviders = this.providers;
        } else {
          // Realizar búsqueda local
          this.filteredProviders = this.products.filter(option =>
            option.name.toLowerCase().includes(search.toLowerCase())
          );

          if (this.filteredProviders.length === 0) {
            // No se encontraron resultados locales, realizar búsqueda remota
            clearTimeout(this.ajaxTimerProviders);
            this.ajaxTimerProviders = setTimeout(() => {
              this.isLoadingProviders = true;
              this.getProviders(search);
            }, 500);
          }
        }
      },

      async getProviders(q = null) {
        this.isLoadingProviders = true
        let params = {
          q: q,
          company_id: this.company.id
        }
        let url = '/api/v2/providers';
        const {data} = await axios.get(url, {params: params});
        if (data && data.success) {
          this.filteredProviders = data.providers
          this.isLoadingProviders = false
        } else {
          this.isLoadingProviders = false
          this.$notify.error('error_loading_providers_data')
        }
      },

      handleInputActivities(q) {
        clearTimeout(this.ajaxTimerActivities);

        if (q.length >= 3) {
          this.ajaxTimerActivities = setTimeout(() => {
            this.getActivities(q);
          }, 500);
        } else {
          this.activities = [];
        }
      },

      async getActivities(q = null) {
        this.isLoadingActivities = true
        let params = {
          'p': this.projectId,
          'n': q,
          's': 1
        }
        const {data} = await axios.get('/api/v2/activities', { params: params });
        if (data && data.success) {
          // this.activities = data.activities
          let entities = []

          data.activities.forEach(item => {
            let entity = {
              id: item.id,
              label: item.name,
              children: []
            }

            if( item.subactivities.length ){
              entity.children = item.subactivities.map( subactivity => {
                return { id: subactivity.id, label: subactivity.name }
              })
            }

            entities.push(entity)
          });

          this.activities = entities

          this.isLoadingActivities = false
        } else {
          this.isLoadingActivities = false
          this.$notify.error('error_loading_activities')
        }
      },

      async getExtrafields() {
        const { data } = await axios.get('/api/v2/project/' + this.projectId + '/store-extrafields')
        if (data && data.success) {

          this.extrafields = data.extrafields;
          if( this.extrafields.length )  {
            let field = this.extrafields.find( item => item.name.toLowerCase() === 'propósito' )
            if( field && field.values) {
              this.purposes = field.values
            }

            field = this.extrafields.find( item => item.name.toLowerCase() === 'tipo producto' )
            if( field && field.values) {
              this.typeProducts = field.values
            }

            field = this.extrafields.find( item => item.name.toLowerCase() === 'ubicación almacén' )
            if( field && field.values) {
              this.warehouses = field.values
            }
          }
        } else {
          this.$notify.error('error_loading_extra_fields_data')
        }
      },

      applyFiltersProduct(value, id) {
        this.applyFilters(1);
      },

      applyFiltersProvider(value, id) {
        this.applyFilters(1);
      },

      applyFiltersActivity(value, id) {
        this.applyFilters(1);
      },

      applyFilters(page) {
        // let args = this.generateFilters();
        // this.$emit('filters-updated', args);
        this.$emit('filters-updated', true);
      },

      cleanFiltersClicked() {
        this.filterProduct = null;
        this.filterProvider = null;
        this.filterActivity = null;
        this.filterPurposeId = 0;
        this.filterWarehouseId = 0;
        this.filterTypeProductId = 0;
        this.filterStockMin = 0;
        this.filterMainDiameter = null;
        this.filterDeliveryNote = null;
        this.filterDatedStart = null;
        this.filterDateEnd = null;
        this.rangeDate = [];
        this.page = 1; //TODO no deberíamos paginar a esta altura
        this.isLoadingTable = true;
        this.applyFilters(1); //TODO no deberíamos paginar a esta altura
      },

    },

  };
</script>

<style>
  .filters-collapse {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .filters-trigger:hover {
    background-color: #f8fafc;
  }
</style>
